body {
    background: #cfd8dc;
    color: #37474f;
    font-size: 1em;
    font-family: "Raleway", Arial, sans-serif;
    /* padding-top: 100px; */
}

.wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.outer {
    display: table;
    position: absolute;
    height: 100%;
    width: 100%;
}

.middle {
    display: table-cell;
    vertical-align: middle;
}

.inner {
    margin-left: auto;
    margin-right: auto;
    width: 800px;
    text-align: center;
}

.error-message {
    color: red;
}
