.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    padding: 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    margin-bottom: 20px;
    box-shadow: 2px 2px 2px #888888;
}

.shadow {
    box-shadow: 5px 5px 5px #888888;
}

/* Box colors */
.bg-1 {
    background: white;
    color: #37474f;
}

.sign-in-box {
    display: inline-block;
    margin-top: 80px;
    width: 500px;
}

.input-box {
}

.footer {
    font-size: 12px;
    margin-bottom: 5px;
}

.login-input-table td {
    padding: 3px;
}
